import { useSessionStorage } from '@uidotdev/usehooks'
import { Icon } from '@iconify/react'
import { MypageIcons } from './mypage-icons'
import { useMediaQuery } from '@uidotdev/usehooks'
import TraveSimLogoSvg from '../assets/logo02.png'

type PropsType = {
  isFixButton: boolean
}

export const FooterItems = ({ isFixButton }: PropsType) => {
  const [token] = useSessionStorage<string | null>('token', null)
  const isMd = useMediaQuery('(min-width: 768px)')

  const items = [
    {
      title: '運営会社',
      link: 'https://www.a2network.co.th',
    },
    {
      title: '利用規約',
      link: 'https://www.travesim.com/terms/',
    },
    {
      title: '特定商取引法に基づく表記',
      link: 'https://www.travesim.com/commerce/',
    },
    {
      title: '個人情報の取り扱い',
      link: 'https://www.travesim.com/policy/',
    },
  ]

  return (
    <>
      {token && !isMd ? (
        <MypageIcons />
      ) : !isFixButton ? (
        <>
          <ul className='flex flex-col md:flex-row flex-wrap justify-center gap-y-1 gap-x-12 mx-auto text-xs md:text-sm leading-6 pt-2'>
            {items.map((item) => (
              <li key={item.title}>
                <a
                  href={item.link}
                  target='_blank'
                  className='mx-auto w-fit flex items-center'
                >
                  <span>{item.title}</span>
                  <Icon icon='mingcute:right-line' className='size-3' />
                </a>
              </li>
            ))}
          </ul>
          <a href='/' className='w-24 mx-auto pb-2'>
            <img src={TraveSimLogoSvg.src} />
          </a>
        </>
      ) : (
        ''
      )}
    </>
  )
}
